export const checDeleteMultiple = (vue, data) => {
  const success = data.data.some(item => item.succeeded)
  if (success) {
    vue.$toast.success(data.message, {
      timeout: 3000,
    })
  }
  const itemDeleteFail = data.data.filter(item => !item.succeeded)
  if (itemDeleteFail?.length) {
    itemDeleteFail.forEach(item => {
      vue.$toast.error(item.message, {
        timeout: 3000,
      })
    })
  }
}

export const checDeleteMultiple1 = 1
