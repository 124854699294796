<template>
  <b-card>
    <action-btn
      :selected-number="selectedNumber"
      :actions-permission="[checkQuyenThemMoi, checkQuyenXoa]"
      :actions="['createBtn', 'editorBtn', 'deleteBtn', 'reFresh']"
      @add-item="addCommom"
      @update-item="onUpdate"
      @delete-item="$refs.confirmDelete.showModal()"
      @refresh-page="refreshPage"
    />
    <good-table
      ref="baoCao"
      class="mt-1 fixed-column"
      :columns="columns"
      :rows="danhSachBaoCao"
      :total="tongSoBaoCao"
      :actions-permission="[checkQuyenThemMoi, checkQuyenXoa]"
      @selected-item="onSelectRow"
      @column-filter="filterdanhSachBaoCao"
      @update-item="onUpdate"
      @delete-item="setItemUpdate"
      @page-change="pageChange"
    >
      <template
        slot="custom-filter"
        slot-scope="props"
      >
        <treeselect
          v-if="props.props.column.field === 'tenLoaiBaoCao'"
          id="linhVuc"
          v-model="filter.listLoaiBaoCaoId"
          v-format-v-select
          multiple
          class="vtreeselect-chooser"
          :options="danhSachLoaiBaoCao"
          :limit="0"
          :limit-text="(count) => ` + ${count} lựa chọn`"
          no-options-text="Không có dữ liệu"
          no-results-text="Không có dữ liệu"
          placeholder="Chọn loại trạng thái"
          :clear-on-select="true"
          :before-clear-all="clearTreeSelect"
          @select="nextTickTreeSelect()"
        />
        <treeselect
          v-else-if="props.props.column.field === 'status'"
          id="linhVuc"
          v-model="filter.status"
          v-format-v-select
          class="vtreeselect-chooser"
          :options="optionsTrangThai"
          :limit="0"
          :limit-text="(count) => ` + ${count} lựa chọn`"
          no-options-text="Không có dữ liệu"
          no-results-text="Không có dữ liệu"
          placeholder="Chọn loại trạng thái"
          :clear-on-select="true"
          :before-clear-all="clearTreeSelect"
          @select="nextTickTreeSelect()"
          @deselect="nextTickTreeSelect()"
        />
      </template>
    </good-table>
    <common-modal
      ref="commonModal"
      :title="title"
      @handle-ok="onSave"
      @reset-form="resetForm"
      @handle-focus="handleFocusError"
    >
      <b-form>
        <b-form-group
          id="input-group-1"
          label="Mã báo cáo"
          label-for="input-1"
          class="required"
        >
          <validation-provider
            v-slot="{ errors }"
            key="maBaoCao"
            :rules="{ required: true }"
            name="maBaoCao"
          >
            <b-form-input
              id="maBaoCao"
              ref="maBaoCao"
              v-model="form.maBaoCao"
              placeholder="Nhập mã báo cáo"
              :autofocus="form.id ? false : true"
              type="text"
              :class="{required: errors.length > 0}"
            />
            <span class="text-danger">{{ errors[0] }}</span>
            <span class="text-danger">{{ errorMessage }}</span>
          </validation-provider>
        </b-form-group>
        <b-form-group
          id="input-group-2"
          label="Tên báo cáo"
          label-for="tenBaoCao"
          class="required"
        >
          <validation-provider
            v-slot="{ errors }"
            key="tenBaoCao"
            :rules="{ required: true }"
            name="tenBaoCao"
          >
            <b-form-input
              id="tenBaoCao"
              ref="tenBaoCao"
              v-model="form.tenBaoCao"
              placeholder="Nhập tên báo cáo"
              type="text"
              :class="{required: errors.length > 0}"
            />
            <span class="text-danger">{{ errors[0] }}</span>
          </validation-provider>

        </b-form-group>
        <b-form-group
          id="input-group-3"
          label="Loại báo cáo"
          class="required"
        >
          <validation-provider
            v-slot="{ errors }"
            key="loaiBaoCao"
            :rules="{ required: true }"
            name="loaiBaoCao"
          >
            <treeselect
              id="loaiTrangThai"
              ref="loaiBaoCao"
              v-model="form.loaiBaoCaoId"
              v-format-v-select
              :options="danhSachLoaiBaoCao"
              :class="{required: errors.length > 0}"
              no-options-text="Không có dữ liệu"
              no-results-text="Không có dữ liệu"
              :match-keys="['name']"
              placeholder="Chọn loại báo cáo"
            />
            <span class="text-danger">{{ errors[0] }}</span>
          </validation-provider>
        </b-form-group>
        <div class="d-flex">
          <span class="mr-3">Trạng thái</span>
          <b-form-checkbox
            v-model="form.status"
            switch
          />
        </div>
      </b-form>
      <div
        v-if="isShow"
        slot="footer"
      >
        <b-button
          variant="primary"
          size="sm"
          class="mr-1"
          @click="$refs.commonModal.checkValidate()"
        >
          Lưu
        </b-button>
        <b-button
          variant="danger"
          size="sm"
          @click="openModal"
        >
          Đóng
        </b-button>
      </div>
    </common-modal>
    <common-modal
      ref="commonnModal"
      title="Xác nhận"
      size="sm"
    >
      Bạn có xác nhận đóng quá trình nhập liệu này không?
      <div
        v-if="isShowFooter"
        slot="footer"
      >
        <b-button
          variant="primary"
          size="sm"
          class="mr-1"
          @click="$refs.commonModal.hideModal(),$refs.commonnModal.hideModal()"
        >
          Đồng ý
        </b-button>
        <b-button
          variant="danger"
          size="sm"
          @click="$refs.commonnModal.hideModal()"
        >
          Đóng
        </b-button>
      </div>
    </common-modal>
    <common-modal
      ref="confirmDelete"
      title="Xác nhận"
      size="sm"
      @handle-ok="onDelete"
    />
  </b-card>
</template>
<script>
import {
  BCard,
  BFormInput,
  BFormGroup,
  BButton,
  BForm,
  BFormCheckbox,
} from 'bootstrap-vue'
import addCssSemantic from '@/mixins/mixins'
import ActionBtn from '@/modules/danh-muc/components/common/ActionBtn.vue'
import CommonModal from '@/modules/danh-muc/components/modal/CommonModal.vue'
import GoodTable from '@/components/GoodTable.vue'
import { ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import END_POINTS from '@/api/endpoints'
import { PERMISSION_NAME } from '@/constants/constants'
import { hasPermission } from '@/utils/permission-utils'
// eslint-disable-next-line import/named
import _cloneDeep from 'lodash/cloneDeep'
import { compareObjects } from '@/utils'
import { STATUS } from '../../../../constants/constants'
import { checDeleteMultiple } from '../../../../utils/common-utils'

export default {
  components: {
    ActionBtn,
    GoodTable,
    BCard,
    BButton,
    BFormInput,
    BFormGroup,
    BForm,
    BFormCheckbox,
    CommonModal,
    ValidationProvider,
  },
  mixins: [addCssSemantic],
  data() {
    return {
      required,
      optionsTrangThai: STATUS,
      selectedNumber: 0,
      title: 'Thêm mới báo cáo',
      isCreate: true,
      columns: [
        {
          label: 'STT',
          thClass: 'text-center custom-style',
          tdClass: 'text-center',
          width: '50px',
          field: 'STT',
        },
        {
          label: 'Mã báo cáo',
          field: 'maBaoCao',
          thClass: 'text-center',
          width: '150px',
          filterOptions: {
            enabled: true,
            placeholder: 'Tìm kiếm',
            filterValue: null,
          },
        },
        {
          label: 'Tên báo cáo',
          field: 'tenBaoCao',
          thClass: 'text-center',
          width: 'auto',
          filterOptions: {
            enabled: true,
            placeholder: 'Tìm kiếm',
            filterValue: null,
          },
        },
        {
          label: 'Loại báo cáo',
          field: 'tenLoaiBaoCao',
          thClass: 'text-center',
          width: '200px',
          filterOptions: {
            enabled: true,
            placeholder: 'Tìm kiếm',
            filterValue: null,
          },
        },
        {
          label: 'Trạng thái',
          field: 'status',
          width: '180px',
          thClass: 'text-center',
          tdClass: 'text-left',
          filterOptions: {
            enabled: true,
            placeholder: 'Tìm kiếm',
            filterValue: null,
          },
        },
        {
          label: 'Thao tác',
          thClass: 'text-center',
          tdClass: 'vertical-align-middle',
          field: 'actions',
          width: '100px',
        },
      ],
      filter: {
        maBaoCao: '',
        tenBaoCao: '',
        status: null,
        listLoaiBaoCaoId: [],
        listLoaiBaoCaoText: 'string',
        pageNumber: 1,
        pageSize: 10,
      },
      form: {
        maBaoCao: '',
        tenBaoCao: '',
        loaiBaoCaoId: null,
        status: true,
      },
      beginObject: {},
      isShow: false,
      isShowFooter: false,
      selectedRows: [],
      danhSachBaoCao: [],
      tongSoBaoCao: 0,
      danhSachLoaiBaoCao: [
        {
          id: 1,
          label: 'Báo cáo nội bộ',
          name: `Báo cáo nội bộ${this.$removeDiacritical('Báo cáo nội bộ')}`,
        },
        {
          id: 2,
          label: 'Báo cáo tổng hợp',
          name: `Báo cáo tổng hợp${this.$removeDiacritical('Báo cáo tổng hợp')}`,
        },
      ],
      errorMessage: '',
    }
  },
  computed: {
    checkQuyenThemMoi() {
      return hasPermission([PERMISSION_NAME.BAO_CAO.THEM_MOI])
    },
    checkQuyenSua() {
      return hasPermission([PERMISSION_NAME.BAO_CAO.SUA])
    },
    checkQuyenXoa() {
      return hasPermission([PERMISSION_NAME.BAO_CAO.XOA])
    },
    checkAllQuyen() {
      return [this.checkQuyenSua, this.checkQuyenXoa].every(item => item === false)
    },
    getColumns() {
      return this.checkAllQuyen ? this.columns.filter(item => item.field !== 'actions') : this.columns
    },
  },
  created() {
    this.filterdanhSachBaoCao()
  },
  methods: {
    openModal() {
      if (compareObjects(this.beginObject, this.form)) {
        this.$refs.commonModal.hideModal()
        this.$refs.commonnModal.hideModal()
      } else {
        this.$refs.commonnModal.showModal()
        this.isShowFooter = true
      }
    },
    pageChange() {
      const data = this.$refs.baoCao.getCurrentPage()
      const payload = {
        ...this.filter,
        pageNumber: data.pageNumber,
        pageSize: data.pageSize,
      }
      this.$axios.post(END_POINTS.BAO_CAO.DS, payload, false).then(response => {
        if (response.data && response.data.succeeded) {
          this.danhSachBaoCao = response.data.data
          this.tongSoBaoCao = response.data.totalCount || 0
        }
      })
    },
    clearTreeSelect() {
      setTimeout(() => {
        this.nextTickTreeSelect()
      }, 200)
      return true
    },
    nextTickTreeSelect() {
      this.$nextTick(() => {
        this.$axios.post(END_POINTS.BAO_CAO.DS, this.filter, false).then(response => {
          if (response.data && response.data.succeeded) {
            this.danhSachBaoCao = response.data.data
            this.tongSoBaoCao = response.data.totalCount || 0
          }
        })
      })
    },
    refreshPage() {
      this.filter = {
        maBaoCao: '',
        tenBaoCao: '',
        status: null,
        listLoaiBaoCaoId: [],
        listLoaiBaoCaoText: 'string',
        pageNumber: 1,
        pageSize: 10,
      }
      this.$refs.baoCao.$refs.vbTables.reset()
      this.$axios.post(END_POINTS.BAO_CAO.DS, this.filter, false).then(response => {
        if (response.data && response.data.succeeded) {
          this.danhSachBaoCao = response.data.data
          this.tongSoBaoCao = response.data.totalCount || 0
        }
      })
    },
    filterdanhSachBaoCao(param = {}) {
      this.filter = {
        ...this.filter,
        ...param,
      }
      let payload = {
        ...this.filter,
      }
      if (this.$refs.baoCao) {
        payload = {
          ...payload,
          ...this.$refs.baoCao.getCurrentPage(),
        }
      }
      if (param.maBaoCao) {
        payload = {
          ...payload,
          pageNumber: 1,
          pageSize: this.filter.pageSize,
          maBaoCao: param.maBaoCao ? param.maBaoCao.replace(/\s+/g, ' ').trim() : '',
        }
      }
      if (param.tenBaoCao) {
        payload = {
          ...payload,
          pageNumber: 1,
          pageSize: this.filter.pageSize,
          tenBaoCao: param.tenBaoCao ? param.tenBaoCao.replace(/\s+/g, ' ').trim() : '',
        }
      }
      if (this.filter.listLoaiBaoCaoId) {
        payload = {
          ...payload,
          pageNumber: 1,
          pageSize: this.filter.pageSize,
        }
      }
      if (this.filter.status || !this.filter.status) {
        payload = {
          ...payload,
          pageNumber: 1,
          pageSize: this.filter.pageSize,
        }
      }
      this.$axios.post(END_POINTS.BAO_CAO.DS, this.$trimObject({
        ...payload,
      }), false).then(response => {
        if (response.data && response.data.succeeded) {
          this.danhSachBaoCao = response.data.data
          this.tongSoBaoCao = response.data.totalCount || 0
        }
      })
    },
    addCommom() {
      this.isShow = true
      this.title = 'Thêm mới báo cáo'
      this.isCreate = true
      this.$refs.commonModal.showModal()
    },
    onSelectRow(rows) {
      this.selectedRows = rows
      this.selectedNumber = rows.length
    },
    onSave() {
      if (this.isCreate) {
        this.$axios.post(END_POINTS.BAO_CAO.THEM, this.$trimObject(this.form), false).then(res => {
          if (res.data?.succeeded) {
            this.filterdanhSachBaoCao()
            this.$refs.commonModal.hideModal()
            this.$toast.success(res.data.message)
            this.errorMessage = ''
          } else {
            this.errorMessage = res.data.message
          }
        })
      } else {
        this.$axios.put(END_POINTS.BAO_CAO.SUA, this.$trimObject(this.form), false).then(res => {
          if (res.data?.succeeded) {
            this.filterdanhSachBaoCao()
            this.$refs.commonModal.hideModal()
            this.$toast.success(res.data.message)
            this.errorMessage = ''
          } else {
            this.errorMessage = res.data.message
          }
        })
      }
    },
    onUpdate(item) {
      this.isShow = true
      this.form = {
        id: item.id,
        maBaoCao: item.maBaoCao,
        tenBaoCao: item.tenBaoCao,
        loaiBaoCaoId: item.loaiBaoCaoId,
        status: item.status,
      }
      this.title = 'Cập nhật báo cáo'
      this.isCreate = false
      this.$nextTick(() => {
        this.beginObject = _cloneDeep(this.form)
        this.$refs.commonModal.showModal()
      })
    },
    onDelete() {
      this.isShow = false
      const payload = {
        listId: this.selectedRows.map(row => row.id),
      }
      this.$axios.delete(END_POINTS.BAO_CAO.XOA, payload, false).then(res => {
        if (res.data?.succeeded) {
          if (this.danhSachBaoCao.length === 1) {
            const currentPage = this.$refs.baoCao.getCurrentPage()
            const index = currentPage.pageNumber - 1
            this.$refs.baoCao.resetCurrentPage(index === 0 ? 1 : index)
          }
          this.filterdanhSachBaoCao()
          checDeleteMultiple(this, res.data)
        }
      })
    },
    setItemUpdate(item) {
      this.selectedRows = [item]
      this.$refs.confirmDelete.showModal()
    },
    resetForm() {
      this.form = {
        maBaoCao: '',
        tenBaoCao: '',
        loaiBaoCaoId: null,
        status: true,
      }
      this.errorMessage = ''
    },
    normalizer(node) {
      return {
        id: node.id,
        value: node.id,
        label: node.tenLoaiTrangThai,
        name: node.tenLoaiTrangThai + this.$removeDiacritical(node.tenLoaiTrangThai),
      }
    },
    handleFocusError(first) {
      if (first) {
        const field = first[0]
        if (['loaiBaoCao'].includes(field)) {
          this.$refs[field].$el.querySelector('input').focus()
        } else {
          this.$refs[field].focus()
        }
      }
    },
  },
}
</script>
