<template>
  <bao-cao />
</template>
<script>
import BaoCao from '@/modules/danh-muc/components/pages/danh-muc/dung-chung/BaoCao.vue'

export default {
  components: {
    BaoCao,
  },
}
</script>
